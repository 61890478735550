@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gradient1 {
  background: rgb(34, 119, 241);
  background: linear-gradient(
    189deg,
    rgba(34, 119, 241, 1) 0%,
    rgba(19, 55, 106, 1) 100%
  );
}
.gradient2 {
  background: rgb(34, 119, 241);
  background: linear-gradient(
    62deg,
    rgba(34, 119, 241, 1) 0%,
    rgba(19, 55, 106, 1) 100%
  );
}
.otpinput > div {
  width: 100%;
}
/* custom slider */

::webkit-scrollbar {
  width: 0.3rem;
}

/* Track */
::webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::webkit-scrollbar-thumb:hover {
  background: #555;
}
